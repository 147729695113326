import React from "react";
import { Button, ImageListItem, Stack, Typography, Grid } from "@mui/material";
import { motion } from "framer-motion";
import imageHero from "../../Image/Group 39.png";
const Hero = () => {
  return (
    <Stack
      sx={{
        height: "100dvh",
        width: "100%",
        backgroundColor: "#F7F7F7",
        padding: "6rem 5%",
      }}
    >

      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} md={6}>
          <Stack alignItems={"flex-start"} gap={2}>
            <Typography fontSize={"25px"} fontWeight={"bold"} color={"#759b90"}>
              Innovation for Impact, Excellence Every Day.
            </Typography>
            <Typography fontSize={"14px"} color={"#888888"}>
              At Infinite Software Solutions, we are committed to driving
              innovation and excellence in the ever-evolving landscape of
              technology. Our dedicated team specializes in delivering
              cutting-edge solutions tailored to meet the unique needs of
              businesses....
            </Typography>
            <Button
              variant="contained"
              sx={{
                background: "#2d4e44",
                "&:hover": { background: "#2d4e4490" },
              }}
            >
              Discover more
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>

          <motion.div
            animate={{ scale: 1 }}
            initial={{ scale: 0.3 }}
            transition={{ delay: 0.5, type: "tween", duration: 0.5 }}
          >
            <ImageListItem>
              <img src={imageHero} alt="hero" />
            </ImageListItem>
          </motion.div>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Hero;
