import { Box, Grid, ImageListItem, Stack, Typography } from "@mui/material";
import React from "react";
import image1 from "../../Image/work1.png";
import image2 from "../../Image/work2.png";
import image3 from "../../Image/work3.png";
import image4 from "../../Image/work4.png";
import image5 from "../../Image/work5.png";
import image6 from "../../Image/work6.png";

const dummyData = [
  {
    id: 1,
    image: image1,
    backgroundColor: "#F8F9FA",
    color: "#192655",
    title: "Neurogen AI",
    description:
      "Web-based platform that allows users to enhance user engagement and provide timely, accurate information on stroke-related concerns, combining the convenience of online assessment with responsive medical guidance.",
  },
  {
    id: 2,
    image: image2,
    backgroundColor: "#272727",
    color: "#F2A730",
    title: "Geez Bank",
    description:
      "Web application that enables users to user-friendly interface for customers to seamlessly view currency rates and explore the bank's services and products. Offering an intuitive design, users can easily navigate through the platform to access real-time currency information, fostering informed decision-making.",
  },
  {
    id: 3,
    image: image3,
    backgroundColor: "#5E5E5E",
    color: "#f1f1ea",
    title: "Music app",
    description:
      "Web application that enables users to explore and enjoy a vast array of musical content. With an intuitive design, users can easily navigate through the platform to discover diverse genres, artists, and playlists. The app ensures a seamless and immersive music experience, allowing users to play, pause, skip tracks, and create personalized playlists.",
  },
  {
    id: 4,
    image: image4,
    backgroundColor: "#1C1C1C",
    color: "#99CB00",
    title: "BC REcipe",
    description:
      "Web application that enables users to effortlessly create, share, and discover recipes. With a user-friendly interface, individuals can input detailed recipes, including ingredients and instructions, while exploring a dynamic listing page. Users can rate and review recipes, like and favorite dishes, and personalize their profiles.",
  },
  {
    id: 5,
    image: image5,
    backgroundColor: "#F9F7F7",
    color: "#112D4E",
    title: "Let'sGo",
    description:
      "Introducing Let'sGo, your ultimate companion for seamless travel experiences. This all-in-one tour and travel app revolutionizes the way you explore the world. With Let'sGo, booking flights, reserving hotels, and arranging taxi services become effortless tasks. Explore a diverse array of destinations and choose from customizable tour packages tailored to your preferences. Whether you're a solo traveler or planning a group adventure, Let'sGo offers convenience and flexibility at your fingertips.",
  },
  {
    id: 6,
    image: image6,
    backgroundColor: "#F3F4F6",
    color: "#272727",
    title: "EfuyeGela",
    description:
      "Introducing EfuyeGela, your go-to platform for efficient job creation and hiring solutions. This innovative web app simplifies the process of posting and finding jobs, acting as a job streamer for employers and job seekers alike. With EfuyeGela, employers can effortlessly create and publish job listings tailored to their specific requirements, reaching a vast pool of potential candidates. Job seekers can browse through a stream of available positions, apply with ease, and showcase their skills and qualifications. Whether you're an employer looking to fill vacancies or a job seeker searching for the perfect opportunity, EfuyeGela streamlines the hiring process for a seamless experience. Join EfuyeGela today and unlock a world of job opportunities at your fingertips.",
  },
];

const OurWork = () => {
  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Stack alignItems={"center"} sx={{ p: 3 }}>
        <Typography fontSize={"14px"} color={"#EF9B01"}>
          Our work
        </Typography>
        <Typography fontSize={"40px"} fontWeight={"bold"} color={"#759b90"}>
          Latest Works
        </Typography>
      </Stack>

      {dummyData.map((item, index) => (
        <Stack
          key={item.id}
          sx={{
            padding: "1rem",
            background: item.backgroundColor,
            color: item.color,
            borderRadius:"4px"
          }}
        >
          {index % 2 === 0 ? (
            <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
              <Grid item xs={12} md={6} >
                <ImageListItem sx={{ width: "100%" }}>
                  <img
                    src={item.image}
                    alt="work"
                    style={{
                      width: "100%",
                    }}
                  />
                </ImageListItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography fontSize={"28px"} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <Typography sx={{ width: "80%" }}>
                    {item.description}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
              <Grid item xs={12} md={6}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography fontSize={"28px"} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <Typography sx={{ width: "80%" }}>
                    {item.description}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageListItem sx={{ width: "100%" }}>
                  <img
                    src={item.image}
                    alt="work"
                    style={{
                      width: "100%",
                    }}
                  />
                </ImageListItem>
              </Grid>
            </Grid>
          )}
        </Stack>
      ))}
    </Box>
  );
};

export default OurWork;
