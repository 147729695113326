import React from "react";
import Layout from "../Components/Layout/Layout";
import Hero from "../Components/Landing/Hero";
import About from "../Components/Landing/About";
import OurWork from "../Components/Landing/OurWork";
import Product from "../Components/Landing/Product";
import Contact from "../Components/Landing/Contact";
import Footer from "../Components/Landing/Footer";

const Landing = () => {
  return (
    <Layout>
      <Hero />
      <About />
      <OurWork />
      <Product />
      <Contact />
      <Footer />
    </Layout>
  );
};

export default Landing;
