import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  ImageListItem,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SwipeableDrawer,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowDropDown, KeyboardArrowUp } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { HashLink } from "react-router-hash-link";

import logo from "../../Image/1 (1).png";
import logo1 from "../../Image/logo.png";

const Navbar = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const linkStyle = {
    color: "#FFFFFF",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  };

  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const navigate = useNavigate();

  const services = (s) => {
    navigate(`/${s}`);
  };

  const [state, setState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    setState(open);
  };

  const routeHandler = (text) => {
    if (text === "Home") {
      navigate(`/`);
    } else {
      navigate(`#${text.toLowerCase().replace(" ", "-")}`);
    }
  };

  return (
    <Stack
      direction={"row"}
      position={"sticky"}
      top={0}
      sx={{
        height: "11dvh",
        backgroundColor: "#2d4e44",
        padding: "0.5rem 5%",
        zIndex: 1,
        borderBottom: "2px solid #759b90",
      }}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <ImageListItem
        sx={{
          width: 250,
          height: "10dvh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="logo" style={{ width: 100 }} />
        <img src={logo1} alt="logo" />
      </ImageListItem>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={5}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Stack direction={"row"} gap={3} alignItems={"center"}>
          <Link style={linkStyle} to={"/"}>
            Home
          </Link>
          <HashLink style={linkStyle} smooth to={"/#our-work"}>
            Our Works
          </HashLink>
          <HashLink style={linkStyle} smooth to={"#about-us"}>
            About us
          </HashLink>
          <Menu
            id="about-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "about-menu",
            }}
          >
            <MenuItem
              onClick={() => {
                services("about-us");
              }}
            >
              About Let's Go
            </MenuItem>
            <MenuItem
              onClick={() => {
                services("organization-structure");
              }}
            >
              Organizational Structure
            </MenuItem>
            <MenuItem
              onClick={() => {
                services("group-companies");
              }}
            >
              Group Companies
            </MenuItem>
          </Menu>
          <HashLink style={linkStyle} smooth to={"#product-&-service"}>
            Product & Service
          </HashLink>
          <Menu
            id="product-menu"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "product-menu",
            }}
          >
            <MenuItem
              onClick={() => {
                services("local-service");
              }}
            >
              Website Design and Development
            </MenuItem>
            <MenuItem
              onClick={() => {
                services("mobile-app");
              }}
            >
              Mobile App Design and Development
            </MenuItem>
            <MenuItem
              onClick={() => {
                services("ui-ux-design");
              }}
            >
              UI/UX Design
            </MenuItem>
            <MenuItem
              onClick={() => {
                services("erp");
              }}
            >
              Enterprise Resource Planning
            </MenuItem>
            <MenuItem
              onClick={() => {
                services("network-installation");
              }}
            >
              Network Installation
            </MenuItem>
          </Menu>
          <HashLink style={linkStyle} smooth to={"#contact-us"}>
            Contact Us
          </HashLink>
        </Stack>
      </Stack>
      <IconButton
        sx={{ display: { xs: "block", md: "none" } }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon sx={{ color: "#FFFFFF", fontSize: 34 }} />
      </IconButton>
      <SwipeableDrawer
        anchor={"right"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{ backgroundColor: "#99999950" }}
      >
        <Box
          sx={{ width: 250, backgroundColor: "#2C4D43", height: "100vh" }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <ImageListItem
              sx={{
                width: 50,
                height: "10dvh",
              }}
            >
              <img src={logo} alt="logo" />
              <img src={logo1} alt="logo" />
            </ImageListItem>
          </Box>
          <Divider />
          <List>
            {[
              "Home",
              "Our Works",
              "About us",
              "Product & Service",
              "Contact Us",
            ].map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                onClick={() => routeHandler(text)}
              >
                <ListItemButton>
                  <ListItemText primary={text} sx={{ color: "#FFFFFF" }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
      {showScrollButton && (
        <IconButton
          sx={{
            position: "fixed",
            bottom: "2rem",
            right: "2rem",
            backgroundColor: "#759B90",
            color: "#FFFFFF",
            zIndex: 10,
            "&:hover": {
              backgroundColor: "#759B9099",
              color: "#272727",
            },
          }}
          onClick={scrollToTop}
        >
          <KeyboardArrowUp />
        </IconButton>
      )}
    </Stack>
  );
};

export default Navbar;
