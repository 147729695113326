import {
  Card,
  CardActionArea,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import image1 from "../../Image/work1.png";
import image2 from "../../Image/work2.png";
import image3 from "../../Image/work3.png";
import image4 from "../../Image/work4.png";
import image5 from "../../Image/work5.png";
import image6 from "../../Image/work6.png";
import { useNavigate } from "react-router-dom";
const OurWork = () => {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const container = containerRef.current;
    let scrollInterval;
    const scrollRight = () => {
      const scrollAmount = 1;
      container.scrollLeft += scrollAmount;
      if (
        container.scrollLeft >=
        container.scrollWidth - container.clientWidth
      ) {
        container.scrollLeft = 0;
      }
    };
    scrollInterval = setInterval(scrollRight, 30);
    return () => clearInterval(scrollInterval);
  }, []);
  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "#2d4e44",
        padding: "6rem 5%",
      }}
      id='our-work'
    >
      <Stack alignItems={"center"}>
        <Typography fontSize={"14px"} color={"#EF9B01"}>
          Our work
        </Typography>
        <Typography fontSize={"40px"} fontWeight={"bold"} color={"#759b90"}>
          Latest Works
        </Typography>
      </Stack>
      <div
        style={{
          width: "100%",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          position: "relative",
        }}
      >
        <style>
          {`
                ::-webkit-scrollbar {
                    display: none;
                }
            `}
        </style>
        <Stack
          direction={"row"}
          gap={3}
          sx={{
            width: "100%",
            padding: "1rem",
            overflowX: "auto",
            "& > *": { flex: "0 0 400px", minWidth: 400, maxWidth: 400 },
            transform: "translateX(0)",
          }}
          ref={containerRef}
        >
          <Card sx={{ width: 400 }} onClick={() => navigate("/our-work")}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={image1}
                alt="News Image"
              />
            </CardActionArea>
          </Card>
          <Card sx={{ width: 400 }} onClick={() => navigate("/our-work")}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={image2}
                alt="News Image"
              />
            </CardActionArea>
          </Card>
          <Card sx={{ width: 400 }} onClick={() => navigate("/our-work")}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={image3}
                alt="News Image"
              />
            </CardActionArea>
          </Card>
          <Card sx={{ width: 400 }} onClick={() => navigate("/our-work")}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={image4}
                alt="News Image"
              />
            </CardActionArea>
          </Card>
          <Card sx={{ width: 400 }} onClick={() => navigate("/our-work")}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={image5}
                alt="News Image"
              />
            </CardActionArea>
          </Card>
          <Card sx={{ width: 400 }} onClick={() => navigate("/our-work")}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={image6}
                alt="News Image"
              />
            </CardActionArea>
          </Card>
        </Stack>
      </div>
    </Stack>
  );
};

export default OurWork;
