import React from 'react'
import Layout from '../Components/Layout/Layout'
import OurWork from '../Components/OurWork/OurWork'
import Footer from '../Components/Landing/Footer'

const AllWork = () => {
  return (
    <Layout>
        <OurWork />
        <Footer />
    </Layout>
  )
}

export default AllWork