import React from "react";
import Layout from "../Components/Layout/Layout";
import Footer from "../Components/Landing/Footer";
import AboutCompany from "../Components/About/AboutCompany";
const About = () => {
  return (
    <Layout>
      <AboutCompany />
      <Footer />
    </Layout>
  );
};

export default About;
