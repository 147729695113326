import {
  Card,
  CardActionArea,
  CardContent,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import {
  Business,
  DesignServices,
  MobileFriendly,
  Router,
  Web,
} from "@mui/icons-material";
import React from "react";

const services = [
  {
    icon: <Web sx={{ fontSize: 48, color: "#2d4e44" }} />,
    title: "Website Design and Development",
    description:
      "Crafting digital experiences, building innovative websites to lead in technology.",
  },
  {
    icon: <MobileFriendly sx={{ fontSize: 48, color: "#2d4e44" }} />,
    title: "Mobile App Design and Development",
    description:
      "Crafting innovative mobile experiences, developing cutting-edge mobile applications.",
  },
  {
    icon: <DesignServices sx={{ fontSize: 48, color: "#2d4e44" }} />,
    title: "UI/UX Design",
    description:
      "Crafting intuitive and visually appealing user interfaces, ensuring a seamless user experience.",
  },
  {
    icon: <Business sx={{ fontSize: 48, color: "#2d4e44" }} />,
    title: "Enterprise Resource Planning",
    description:
      "Streamlining business processes, integrating systems, and enhancing efficiency with ERP solutions.",
  },
  {
    icon: <Router sx={{ fontSize: 48, color: "#2d4e44" }} />,
    title: "Network Installation",
    description:
      "Reliable network setup and installation services, ensuring seamless connectivity for your business.",
  },
];

const Product = () => {
  return (
    <Stack sx={{ width: "100%", padding: "6rem 5%" }} gap={2} id={'product-&-service'}>
      <Stack alignItems="center">
        <Typography fontSize="14px" color="#EF9B01">
          Product and Service
        </Typography>
        <Typography fontSize="40px" fontWeight="bold" color="#759b90">
          Our Services
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-around"
        flexWrap="wrap"
        gap={4}
      >
        {services.map((service, index) => (
          <Card
            key={index}
            sx={{ maxWidth: 370, boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
          >
            <CardActionArea sx={{ padding: 2 }}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <Icon sx={{ fontSize: 48 }}>{service.icon}</Icon>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  textAlign="center"
                  color="#37474F"
                >
                  {service.title}
                </Typography>
                <Typography color="#888888">{service.description}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
};

export default Product;
