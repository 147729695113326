import React from "react";
import Layout from "../Components/Layout/Layout";
import Footer from "../Components/Landing/Footer";
import CompanyService from "../Components/Service/CompanyService";
const ServiceCompany = () => {
  return (
    <Layout>
      <CompanyService />
      <Footer />
    </Layout>
  );
};

export default ServiceCompany;
