import {
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  Phone,
  Telegram,
  Twitter,
} from "@mui/icons-material";
import {
  Grid,
  Icon,
  IconButton,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import logo from "../../Image/logos.png";
import { Link } from "react-router-dom";
const linkStyle = {
  color: "#FFFFFF",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
};
const Footer = () => {
  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "#272727",
      }}
    >
      <Grid container spacing={2} alignItems={"flex-start"} sx={{ padding: "2rem 5%" }}>
        <Grid item xs={6} md={3}>
          <Stack sx={{ color: "#FFFFFF" }} gap={2}>
            <Typography variant="h5" color={"#759b90"}>
              Quick Access
            </Typography>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              Our Work
            </Link>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              About us
            </Link>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              Product and Service
            </Link>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              Contact Us
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={6} md={3}>
          <Stack sx={{ color: "#FFFFFF" }} gap={2}>
            <Typography variant="h5" color={"#759b90"}>
              About Us
            </Typography>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              About Infinite
            </Link>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              Organizational Structure
            </Link>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              Group Company
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={6} md={3}>
          <Stack sx={{ color: "#FFFFFF" }} gap={2}>
            <Typography variant="h5" color={"#759b90"}>
              Service and Product
            </Typography>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              Website Design and Development
            </Link>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              Mobile App Design and Development
            </Link>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              UI/UX Design
            </Link>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              Enterprise Resource Planning
            </Link>
            <Link
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                "&:hover": { color: "#2D4E44" },
              }}
            >
              Network Installation
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={6} md={3}>
          <Stack sx={{ color: "#FFFFFF" }} gap={2}>
            <Typography variant="h5" color={"#759b90"}>
              Contact info
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={2}
            >
              <Icon>
                <Email />
              </Icon>
              <Typography variant="body2">contact@letsgotnt.com</Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={2}
            >
              <Icon>
                <Phone />
              </Icon>
              <Typography variant="body2">+251 943 852 394</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ padding: ".5rem 5%", backgroundColor: "#2d4e44" }}
      >
        <Typography color={"#999999"} fontSize={'11px'}>
          Copyright© 2023 Infinite Software Solution S.C. | All Rights Reserved
        </Typography>
        <ImageListItem>
          <img src={logo} alt="logo" style={{ maxHeight: "50px" }} />
        </ImageListItem>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <IconButton
            style={linkStyle}
            onClick={() => window.open("http://www.facebook.com", "_block")}
          >
            <Facebook />
          </IconButton>
          <IconButton
            style={linkStyle}
            onClick={() => window.open("http://www.facebook.com", "_block")}
          >
            <Twitter />
          </IconButton>
          <IconButton
            style={linkStyle}
            onClick={() => window.open("http://www.facebook.com", "_block")}
          >
            <Instagram />
          </IconButton>
          <IconButton
            style={linkStyle}
            onClick={() => window.open("http://www.facebook.com", "_block")}
          >
            <LinkedIn />
          </IconButton>
          <IconButton
            style={linkStyle}
            onClick={() => window.open("http://www.facebook.com", "_block")}
          >
            <Telegram />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
