import { Email, LocationOn, Phone } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const Contact = () => {
  const [Emails, setEmail] = React.useState();
  const [subject, setSubject] = React.useState();
  const [message, setMessage] = React.useState();
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(Emails, subject, message);
  };
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ p: 4 }}
      id={'contact-us'}
    >
      <Stack direction={"row"} justifyContent={"center"} flexWrap={"wrap"} >
        <Stack sx={{ width: 450, p: 2 }} gap={2}>
          <Typography variant="h4" color={"#759b90"}>
            Contact Us
          </Typography>
          <Typography variant="body2">
            Fill free to contact us any time .we will get back to you as soon as
            we can !
          </Typography>
          <Paper
            component={"form"}
            onSubmit={submitHandler}
            sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}
          >
            <FormControl fullWidth>
              <TextField
                label={"Email"}
                size="small"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label={"subject"}
                size="small"
                type="text"
                onChange={(e) => setSubject(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Massage</FormLabel>
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
                style={{ resize: "none", fontSize: 16, padding: "4px" }}
              ></textarea>
            </FormControl>
            <Box textAlign={"right"}>
              <Button
                variant="contained"
                sx={{
                  background: "#2d4e44",
                  "&:hover": { background: "#2d4e4490" },
                }}
              >
                Send
              </Button>
            </Box>
          </Paper>
        </Stack>
        <Stack
          sx={{ width: 450, background: "#2d4e44", p: 2, color: "#FFFFFF" }}
          gap={2}
        >
          <Typography variant="h4" color={"#759b90"}>
            Contact info
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={2}
          >
            <Icon>
              <Email />
            </Icon>
            <Typography variant="body2">contact@letsgotnt.com</Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={2}
          >
            <Icon>
              <Phone />
            </Icon>
            <Typography variant="body2">+251 943 852 394</Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={2}
          >
            <Icon>
              <LocationOn />
            </Icon>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15761.821165972622!2d38.8355686!3d9.0221648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x64d206dc3a577468!2sDan%20Energy%20Research%20and%20Development%20PLC!5e0!3m2!1sen!2set!4v1673861617573!5m2!1sen!2set"
              width="300"
              height="200"
              loading="lazy"
            ></iframe>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Contact;
