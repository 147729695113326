import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import heroImage from "../../Image/logos.png";
import mission from "../../Image/mission.png";
import vision from "../../Image/vision.png";
import value from "../../Image/value.png";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: "6rem 5%",
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
      id={'about-us'}
    >

      <Grid container spacing={2} alignItems={"center"} justifyContent={"space-around"}>
        <Grid item xs={12} md={6}>
          <ImageListItem >
            <img src={heroImage} alt="heroImage" />
          </ImageListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={2}
          >
            <Stack>
              <Typography fontSize={"14px"} color={"#EF9B01"}>
                welcome to Infinite
              </Typography>
              <Typography fontSize={"25px"} fontWeight={"bold"} color={"#759b90"}>
                Innovation for Impact, Excellence Every Day.
              </Typography>
            </Stack>
            <Typography fontSize={"14px"} color={"#888888"}>
              At Infinite Software Solutions, we are committed to driving
              innovation and excellence in the ever-evolving landscape of
              technology. Our dedicated team specializes in delivering
              cutting-edge solutions tailored to meet the unique needs of
              businesses. From seamless Network Installations to comprehensive
              Enterprise Resource Planning (ERP), captivating UI/UX Design, and
              expertly crafted Mobile and Website Development, we pave the way for
              your success in the digital realm. Let's embark on a journey
              together, where technology meets possibility, and your vision
              transforms into reality. Infinite beyond boundaries, explore new
              horizons, and build a future powered by technology.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/about-us")}
              sx={{
                background: "#2d4e44",
                "&:hover": { background: "#2d4e4490" },
              }}
            >
              Discover more
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Stack direction={"row"} justifyContent={"space-around"} flexWrap={"wrap"} gap={2}>
        <Card sx={{ maxWidth: 370, "&:hover": { background: "#2d4e44", color: "#FFFFFF" } }}>
          <CardActionArea sx={{ padding: 5 }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <ImageListItem sx={{ width: 80 }}>
                <img src={vision} alt="vision" />
              </ImageListItem>
              <Typography variant="h5" fontWeight={"bold"}>
                Vision
              </Typography>
              <Typography sx={{ textAlign: "center" }} color={"#888888"}>
                To lead in technology, inspiring positive change and shaping an
                innovative future.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 370, "&:hover": { background: "#2d4e44", color: "#FFFFFF" } }}>
          <CardActionArea sx={{ padding: 5 }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <ImageListItem sx={{ width: 80 }}>
                <img src={mission} alt="mission" />
              </ImageListItem>
              <Typography variant="h5" fontWeight={"bold"}>
                Mission
              </Typography>
              <Typography sx={{ textAlign: "center" }} color={"#888888"}>
                At Infinite Software Solutions, we empower businesses with
                advanced technology.
                {/* We focus on delivering value through
                Network Installations, ERP, UI/UX Design, and Mobile & Website
                Development. Our goal is to foster growth, enhance user
                experiences, and drive efficiency. By staying ahead in tech, we
                aim to boost our clients' success. */}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 370, "&:hover": { background: "#2d4e44", color: "#FFFFFF" } }}>
          <CardActionArea sx={{ padding: 5 }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <ImageListItem sx={{ width: 80 }}>
                <img src={value} alt="value" />
              </ImageListItem>
              <Typography variant="h5" fontWeight={"bold"}>
                Core Value
              </Typography>
              <Typography sx={{ textAlign: "center" }} color={"#888888"}>
                Innovation , Integrity , Collaboration , Excellence ,
                Adaptability and Client-Centric Approach
                {/* Innovation: Always seeking new ideas and tech solutions.
                    Integrity: Upholding honesty, transparency, and ethical practices.
                    Collaboration: Working closely with clients and partners.
                    Excellence: Committed to delivering high-quality work.
                    Adaptability: Staying agile and responsive to changes.
                    Client-Centric Approach: Putting clients at the center of everything we do. */}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Stack>
    </Box>
  );
};

export default About;
