import { Route, Routes } from "react-router-dom";
import Landing from "./Pages/Landing";
import AllWork from "./Pages/AllWork";
import About from "./Pages/About";
import ServiceCompany from "./Pages/ServiceCompany";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/about" element={<About />} />
      <Route path="/service" element={<ServiceCompany />} />
      <Route path="/our-work" element={<AllWork />} />
    </Routes>
  );
}

export default App;
